import './App.css';
import { RESTClientImpl } from './features/services/RESTClient';
import { RESTClientUTImpl } from './features/services/RESTClientUT';
import CHCLayout from './features/ui/CHCLayout';

function App() {
    //TODO: Add check for required values and error handling
    return <CHCLayout restClient={
        process.env.NODE_ENV==='development'?
        RESTClientUTImpl.Create(process.env.REACT_APP_REST_API_BASE!, process.env.REACT_APP_AUTH_BASE!, process.env.REACT_APP_USER_API_BASE!) :
        RESTClientImpl.Create(process.env.REACT_APP_REST_API_BASE!, process.env.REACT_APP_AUTH_BASE!, process.env.REACT_APP_USER_API_BASE!)
    }/>;
}

export default App;
