import { createSlice } from '@reduxjs/toolkit';
import { Role } from '../common/enums/Role';
import { UserStatus } from '../common/enums/UserStatus';
import { RootState } from './store';

export interface UserState {
    status: UserStatus;
    previousStatus: UserStatus;
    userName?: string;
    refreshToken?: string;
    idToken?: string;
    targetGroups?: string[];
    roles?: Role[];
}

const initialState: UserState = {
    status: UserStatus.AUTHENTICATING,
    previousStatus: UserStatus.OFFLINE,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    SetUserStatus: (state, action) => {  state.previousStatus = state.status; state.status = action.payload; },
    SetUserName: (state, action) => {  state.userName = action.payload; },
    SetUserRefreshToken: (state, action) => {  state.refreshToken = action.payload; },
    SetUserIdToken: (state, action) => {  state.idToken = action.payload; },
    SetUserTargetGroups: (state, action) => {  state.targetGroups = action.payload; },
    SetUserRoles: (state, action) => { state.roles = action.payload; }
  },
});

export const GetUserStatus = (state: RootState) => state.user.status;
export const GetPreviousUserStatus = (state: RootState) => state.user.previousStatus;
export const GetUserName = (state: RootState) => state.user.userName;
export const GetUserRefreshToken = (state: RootState) => state.user.refreshToken;
export const GetUserIdToken = (state: RootState) => state.user.idToken;
export const GetUserTargetGroups = (state: RootState) => state.user.targetGroups;
export const GetUserRoles = (state: RootState) => state.user.roles;

export const {
  SetUserRoles,
  SetUserStatus,
  SetUserName,
  SetUserRefreshToken,
  SetUserIdToken,
  SetUserTargetGroups
} = userSlice.actions;

export default userSlice.reducer;
