import { EditOutlined, MinusOutlined, PlusOutlined, ReloadOutlined, WarningOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Divider, Form, Input, message, Modal, Select, Spin, Table, Tag, Tooltip, Typography } from "antd";
import { ValidateStatus } from "antd/lib/form/FormItem";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CacheGroups, CacheUsers, GetCachedGroups, GetCachedUsers, InvalidateConfigCache, InvalidateGroupsCache, InvalidateUsersCache } from "../../../app/cacheSlice";
import { GetUserIdToken } from "../../../app/userSlice";
import { SSPTabs } from "../../../common/enums/SSPTabs";
import { StatusCode } from "../../../common/enums/StatusCode";
import { IRESTClient } from "../../../common/interfaces/IRestClient";
import { Group, IGroup } from "../../../common/models/Group";
import { IUser, User } from "../../../common/models/User";
import ErrorPage from "./ErrorPage";

const { Text } = Typography;

export interface UserPageProps {
    client: IRESTClient
}

enum ModelView {
    ADD_USER_TO_GROUP,
    REM_USER_FROM_GROUP,
    CREATE_GROUP,
    UPDATE_GROUP,
    DELETE_GROUP
}

/**
 * Users Page Component
 * @param props Prop definitions
 * @returns 
 */
const UsersPage = (props: UserPageProps) => {
    const { client } = props;
    const dispatch = useDispatch();
    const idToken = useSelector(GetUserIdToken);
    const [loadingData, SetLoadingData] = useState(true);
    const UserCache = useSelector(GetCachedUsers);
    const GroupCache = useSelector(GetCachedGroups);
    const [focusedUser, SetFocusedUser] = useState<string | undefined>(undefined);
    const [focusedGroup, SetFocusedGroup] = useState<string | undefined>(undefined);
    const [modelIsVisible, SetModelIsVisible] = useState<boolean>(false);
    const [requestPending, SetRequestPending] = useState<boolean>(false);
    const [modelView, SetModelView] = useState<ModelView | undefined>(undefined);
    const [userDataSource, SetUserDataSource] = useState<any[]>([]);
    const [groupDataSource, SetGroupDataSource] = useState<any[]>([]);
    const [activeTabKey, SetActiveKey] = useState<string>('users');
    const Tabs = [{ tab: 'Users', key: SSPTabs.USERS }, { tab: 'Groups', key: SSPTabs.GROUPS }];

    const IsReservedGroup = (groupName: string) => {
        return ['supervisor', 'administrator', 'developer'].includes(groupName);
    }

    const groupColumns: ColumnsType<IGroup> = [
        {
            title: 'GroupName',
            dataIndex: 'groupName',
            key: 'groupName',
            sorter: (left, right) => left.groupName?.localeCompare(right.groupName),
            render: (_, record) => <Tag color={IsReservedGroup(record.groupName) ? 'grey' : 'geekblue'}>{record.groupName}</Tag>
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <Button
                            disabled={IsReservedGroup(record.groupName)}
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={() => {
                                SetFocusedGroup(record.groupName);
                                SetModelView(ModelView.UPDATE_GROUP);
                                SetModelIsVisible(true)
                            }}>Edit</Button>
                        <Button
                            disabled={IsReservedGroup(record.groupName)}
                            danger
                            type="ghost"
                            icon={<MinusOutlined />}
                            onClick={() => {
                                SetFocusedGroup(record.groupName);
                                SetModelView(ModelView.DELETE_GROUP);
                                SetModelIsVisible(true)
                            }}>Remove</Button>
                    </div>);
            },
        }
    ];

    const userColumns: ColumnsType<IUser> = [
        {
            title: 'UserName',
            dataIndex: 'userName',
            key: 'userName',
            sorter: (left, right) => left.userName?.localeCompare(right.userName)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                SetFocusedUser(record.userName);
                                SetModelView(ModelView.ADD_USER_TO_GROUP);
                                SetModelIsVisible(true);
                            }}>Add to a group</Button>
                        <Button
                            danger
                            type="ghost"
                            icon={<MinusOutlined />}
                            onClick={() => {
                                SetFocusedUser(record.userName);
                                SetModelView(ModelView.REM_USER_FROM_GROUP);
                                SetModelIsVisible(true);
                            }}>Remove from a Group</Button>
                    </div>);
            },
        }
    ];

    /**
     * Helper method to invalide the local user cache.
     * This will force the application to fetch the data
     */
    function InvalidateLocalCache() {
        dispatch(InvalidateConfigCache());
        dispatch(InvalidateGroupsCache());
        dispatch(InvalidateUsersCache());
    }

    /**
     * This helper method takes in a cache and builds the data arrays for the tables to display.
     * @param cache The User or Group cache to build the data from
     * @returns 
     */
    function BuildTableData(cache: { [key: string]: Group | User }): any[] {
        let data: any = [];
        Object.entries(cache).forEach((entry) => {
            let _data: { [key: string]: string } = {}
            let entryKey = entry[0];
            let entryData = entry[1];
            Object.entries(entryData).forEach((entryData) => {
                let name = entryData[0];
                let value = entryData[1];
                _data[name] = value;
            })
            _data.key = entryKey;
            data.push(_data);
        });
        SetLoadingData(false);
        return data;
    }

    interface ModelViewProps {
        client: IRESTClient
    }

    /**
     * Generates a Card Form with input fields to create a new group in the SSP/Cognito User Pool
     * @returns A form to create a new group that follows the group definition standard.
     */
    const AddGroupModelView = (props: ModelViewProps) => {
        let { client } = props;
        const groupPattern = process.env.REACT_APP_GROUP_FILTER_PATTERN;
        if (groupPattern === undefined)
            return <ErrorPage errorMessage="Missing a group filter patern definition! Please try again or contact an administrator." />
        const OnFinish = async (group: Group) => {
            if (idToken) {
                SetRequestPending(true);
                await client.AddGroup(group, idToken)
                    .then((response) => {
                        if (response.status === StatusCode.SUCCESS) {
                            // Since we are not getting back the updated list in the response we need to force fresh the latest
                            SetModelView(undefined);
                            SetModelIsVisible(false);
                            SetRequestPending(false);
                            InvalidateLocalCache();
                            message.success("Request succeeded");
                            //TODO: Update the local cache when the event is received not here
                            return;
                        }
                        message.error(`${response.status}`);
                    })
                    .catch((error) => {
                        message.error(error);
                    })
            } else {
                message.error("Missing or invalid token!");
            }
        }
        return (
            <Card
                title={`Create a new group`}>
                <Text>Complete the form below to create a new group in the Self Service Portal.
                    A queue should exist in both the relative contact center and its deployment configuration for the group to work as expected.</Text>
                <Divider />
                <Form
                    onFinish={OnFinish}
                    layout="vertical"
                    name="createGroup"
                    size="large">
                    <Form.Item
                        tooltip="Group names cannot be edited once they have been created."
                        required
                        rules={[{
                            required: true,
                            message: `Group name is required, and must match the following expression ${groupPattern}`,
                            pattern: new RegExp(groupPattern)
                        }]}
                        key='groupName'
                        name='groupName'
                        label='GroupName'>
                        <Input></Input>
                    </Form.Item>

                    <Form.Item
                        tooltip="Provide a brief description about this group."
                        key='description'
                        name='description'
                        label='Description (optional)'>
                        <Input></Input>
                    </Form.Item>

                    {/* TODO: Need to test the error handling for this better before enabling */}
                    {/* <Form.Item
                    tooltip="Provide an optional ID or ARN to the group."
                    key='id'
                    name='id'
                    label='Id (optional)'>
                            <Input></Input>
                    </Form.Item> */}

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">Create group</Button>
                    </Form.Item>
                </Form>
            </Card>);
    }

    /**
     * Generates a Card Form with input fields to edit the group fields for editable values.
     * @returns A form where you can update the groups decription ( or attach an ARN/ID for arn validation)
     */
    const UpdateGroupModelView = (props: ModelViewProps) => {
        const { client } = props;
        if (focusedGroup === undefined)
            return <ErrorPage errorMessage={"Something went wrong when generating the form."} />;
        let groupData: Group = GroupCache[focusedGroup];
        if (groupData === undefined)
            return <ErrorPage errorMessage={"Something went wrong when generating the form."} />;
        const OnFinish = async (event: IGroup) => {
            if (idToken) {
                SetRequestPending(true);
                await client.UpdateGroup(new Group(event.groupName, event.description, event.id), idToken)
                    .then((response) => {
                        if (response.status === StatusCode.SUCCESS) {
                            SetModelView(undefined);
                            SetModelIsVisible(false);
                            InvalidateLocalCache();
                            SetRequestPending(false);
                            message.success("Request succeeded");
                            //TODO: Update the local cache when the event is received not here
                            return;
                        }
                        message.error(`${response.status}`);
                    })
                    .catch((error) => {
                        message.error(error);
                    });
            }
        }

        return (
            <Card
                title={`Editing ${focusedGroup}`}>
                <Form
                    onFinish={OnFinish}
                    layout="vertical"
                    name="updateGroup"
                    size="large">
                    <Form.Item
                        tooltip="Group names cannot be edited once they have been created."
                        required
                        initialValue={focusedGroup}
                        key='groupName'
                        name='groupName'
                        label='GroupName'>
                        <Input disabled></Input>
                    </Form.Item>

                    <Form.Item
                        tooltip="Provide a brief description about this group."
                        initialValue={groupData.description ? groupData.description : undefined}
                        key='description'
                        name='description'
                        label='Description'>
                        <Input></Input>
                    </Form.Item>

                    {/* Need to do more testing before enabling this  */}
                    {/* <Form.Item
                    tooltip="Provide an optional ID or ARN to the group. This may or may not integrate with backend invocations to provide support for tagging invocations."
                    initialValue={groupData.id? groupData.id : undefined}
                    key='id'
                    name='id'
                    label='Id'>
                            <Input></Input>
                    </Form.Item> */}

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">Save Changes</Button>
                    </Form.Item>
                </Form>
            </Card>);
    }

    /**
     * Generates a Card Form with a single input to confirm that you want to remove a group before doing so. Requires the user
     * to type in the group name as shown before the group will be removed.
     * @returns A form with an input field to validate that you want to remove the group before actually doing so.
     */
    const RemoveGroupModelView = (props: ModelViewProps) => {
        const [validateStatus, SetValidateStatus] = useState<ValidateStatus>('error');
        if (focusedGroup === undefined)
            return <ErrorPage errorMessage={"Something went wrong when generating the form."} />;
        let groupData: Group = GroupCache[focusedGroup];
        if (groupData === undefined)
            return <ErrorPage errorMessage={"Something went wrong when generating the form."} />;
        const OnChange = async (event: any) => {
            if (event.target.value === focusedGroup)
                SetValidateStatus('success');
        }
        const OnFinish = async (event: any) => {
            if (idToken) {
                SetRequestPending(true);
                await client.RemoveGroup(new Group(event.group), idToken)
                    .then((response) => {
                        if (response.status === StatusCode.SUCCESS) {
                            SetModelView(undefined);
                            SetModelIsVisible(false);
                            InvalidateLocalCache();
                            SetRequestPending(false);
                            message.success("Request succeeded");
                            //TODO: Update the local cache when the event is received not here
                            return;
                        }
                        message.error(`${response.status}`);
                    })
                    .catch((error) => {
                        message.error(error);
                    });
            }
        }

        return (
            <Card
                title={`Removing group ${focusedGroup}`}
                bordered={false}>
                <Alert
                    showIcon
                    icon={<WarningOutlined />}
                    type="warning"
                    message={`Removing this group will result in all members being removed from the group. Are you sure you want to remove this group ?`} />
                <Form
                    onFinish={OnFinish}
                    layout="horizontal"
                    name="removeGroup"
                    size="large">
                    <Divider />
                    <Form.Item
                        validateStatus={validateStatus}
                        help={`Enter the group name ${focusedGroup} to confirm you want to delete it.`}
                        key={1}
                        name='group'>
                        <Input onChange={OnChange} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button disabled={validateStatus !== 'success'} danger type="primary" htmlType="submit">Remove group</Button>
                    </Form.Item>
                </Form>
            </Card>);
    }

    /**
     * Generates a Card Form with the focused users list of groups where they are NOT already a member and therfor can be inserted into.
     * @returns A form with a drop down of the available groups that this user is NOT currently a part of.
     */
    const AddUserToGroupModelView = (props: ModelViewProps) => {
        if (focusedUser === undefined)
            return <ErrorPage errorMessage={"Something went wrong when generating the form."} />;
        let userData: IUser = UserCache[focusedUser];
        if (userData === undefined || userData.groups === undefined)
            return <ErrorPage errorMessage={"Something went wrong when generating the form."} />;
        const OnFinish = async (event: any) => {
            if (idToken) {
                SetRequestPending(true);
                await client.AddUserToGroup(focusedUser, event.group, idToken)
                    .then((response) => {
                        if (response.status === StatusCode.SUCCESS) {
                            SetModelView(undefined);
                            SetModelIsVisible(false);
                            InvalidateLocalCache();
                            SetRequestPending(false);
                            message.success("Request succeeded");
                            //TODO: Update the local cache when the event is received not here
                            return;
                        }
                        message.error(`${response.status}`);
                    })
                    .catch((error) => {
                        message.error(error);
                    });
                return;
            }
            message.error(`Missing or invalid token.`);
        }
        return (
            <Card
                title={<Text style={{ fontSize: 22 }}>Add user <Tag color='geekblue' style={{ fontSize: 22, fontWeight: 'bold' }}>{focusedUser}</Tag> to group:</Text>}
                bordered={false}>
                <Form
                    onFinish={OnFinish}
                    layout="horizontal"
                    name="addUserToGroup"
                    size="large">
                    <Form.Item
                        rules={[{ required: true, message: 'You must select a group!' }]}
                        key={1}
                        label={"Group"}
                        name='group'>
                        <Select style={{ width: '100%' }}>
                            {Object.keys(GroupCache).map((entry) => {
                                if (userData.groups?.includes(entry))
                                    return;
                                return (<Select.Option key={entry} value={entry}>{entry}</Select.Option>);
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">Add user to group</Button>
                    </Form.Item>
                </Form>
            </Card>);
    }

    /**
     * Generates a Card Form with the focused users list of groups based on the local cache.
     * @returns A Form with the a drop down of the current users groups to choose from.
     */
    const RemoveUserFromGroupModelView = (props: ModelViewProps) => {
        let { client } = props;
        if (focusedUser === undefined)
            return <ErrorPage errorMessage={"Something went wrong when generating the form."} />;
        let userData: IUser = UserCache[focusedUser];
        if (userData === undefined || userData.groups === undefined)
            return <ErrorPage errorMessage={"Something went wrong when generating the form."} />;

        const OnFinish = async (event: any) => {
            if (idToken) {
                SetRequestPending(true);
                await client.RemoveUserFromGroup(focusedUser, event.group, idToken)
                    .then((response) => {
                        if (response.status === StatusCode.SUCCESS) {
                            SetModelView(undefined);
                            SetModelIsVisible(false);
                            InvalidateLocalCache();
                            SetRequestPending(false);
                            message.success("Request succeeded");
                            //TODO: Update the local cache when the event is received not here
                            return;
                        }
                        message.error(`${response.status}`);
                    })
                    .catch((error) => {
                        message.error(error);
                    });
                return;
            }
            console.log(`Missing or invalid token.`);
        }
        return (
            <Card
                title={<Text style={{ fontSize: 22 }}>Remove user <Tag color='geekblue' style={{ fontSize: 22, fontWeight: 'bold' }}>{focusedUser}</Tag> from group:</Text>}
                bordered={false}>
                <Form
                    onFinish={OnFinish}
                    layout="horizontal"
                    name="removeUserFromGroup"
                    size="large">
                    <Form.Item
                        rules={[{ required: true, message: 'You must select a group!' }]}
                        key={1}
                        label={"Group"}
                        name='group'>
                        <Select style={{ width: '100%' }}>
                            {Object.values(userData.groups).map((entry) => {
                                return (<Select.Option key={entry} value={entry}>{entry}</Select.Option>);
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button danger type="ghost" htmlType="submit">Remove from group</Button>
                    </Form.Item>
                </Form>
            </Card>);
    }

    useEffect(() => {
        if (idToken) {

            // Check the local cache for entries before we build the table
            if (Object.keys(UserCache).length === 0) {
                // Send a request to get the user data if there is nothing cached locally.
                SetLoadingData(true);
                try {
                    client.GetUsers(idToken)
                        .then((response) => {
                            if (response.status !== StatusCode.SUCCESS) {
                                message.error("Unable to fetch groups.");
                                return;
                            }
                            // Iterate the response data and cache the entries 
                            let userDataResponse: any[] = response.body;
                            for (let user in userDataResponse) {
                                let userData: User = userDataResponse[user];
                                let cacheEntry = { 'name': userData.userName, 'body': userData };
                                dispatch(CacheUsers(cacheEntry));
                            }
                            SetLoadingData(false);
                        })
                        .catch((error) => {
                            message.error(`Unable to fetch users.${error}`);
                        });
                } catch (error) {
                    message.error(`Unable to fetch user data.${error}`);
                }
            }

            // Check if the cache contains data and build the table data from the local cache
            if (Object.keys(UserCache).length !== 0) {
                SetUserDataSource(BuildTableData(UserCache));
            } //TODO: Add a error prompt maybe ?

            // Check the local cache for entries before we build the table
            if (Object.keys(GroupCache).length === 0) {
                SetLoadingData(true);
                try {
                    client.GetGroups(idToken)
                        .then((response) => {
                            if (response.status !== StatusCode.SUCCESS) {
                                message.error("Unable to fetch groups.");
                                return;
                            }
                            // Iterate the response data and cache the entries
                            let groupDataResponse: any[] = response.body;
                            for (let group in groupDataResponse) {
                                let groupData: Group = groupDataResponse[group];
                                let cacheEntry = { 'name': groupData.groupName, 'body': groupData };
                                dispatch(CacheGroups(cacheEntry));
                            }
                            SetLoadingData(false);
                        })
                        .catch((error) => {
                            message.error(`Unable to fetch groups.${error}`);
                        });
                } catch (error) {
                    message.error(`Unable to fetch groups.${error}`);
                }
            }

            // Check if the cache contains data and build the table data from the local cache
            if (Object.keys(GroupCache).length !== 0) {
                SetGroupDataSource(BuildTableData(GroupCache));
            } //TODO: Add a error prompt maybe ?

        }
    }, [activeTabKey, client, idToken, UserCache, GroupCache, dispatch]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Tooltip overlay='Refresh the data.'>
                    <Button type="link" icon={<ReloadOutlined />} onClick={() => InvalidateLocalCache()}>Refresh</Button>
                </Tooltip>

                {activeTabKey === SSPTabs.GROUPS &&
                    <Tooltip overlay='Create a new group. New groups should have configurations that align with queue configurations in the deployment configurations and follow the naming convention for your implementation.'>
                        <Button type="link" icon={<PlusOutlined />} onClick={() => { SetModelView(ModelView.CREATE_GROUP); SetModelIsVisible(true) }}>Create a new group</Button>
                    </Tooltip>}
            </div>
            <Modal
                width={'50%'}
                footer={false}
                closable={true}
                destroyOnClose
                onCancel={() => { SetModelView(undefined); SetModelIsVisible(false) }}
                visible={modelIsVisible}>
                <Spin
                    spinning={requestPending}>
                    <Card>
                        {modelView === ModelView.ADD_USER_TO_GROUP && <AddUserToGroupModelView client={client} />}
                        {modelView === ModelView.REM_USER_FROM_GROUP && <RemoveUserFromGroupModelView client={client} />}
                        {modelView === ModelView.CREATE_GROUP && <AddGroupModelView client={client} />}
                        {modelView === ModelView.UPDATE_GROUP && <UpdateGroupModelView client={client} />}
                        {modelView === ModelView.DELETE_GROUP && <RemoveGroupModelView client={client} />}
                    </Card>
                </Spin>
            </Modal>
            <Card
                title={'User and Group Management'}
                bordered={false}
                tabProps={{ type: 'card' }}
                tabList={Tabs}
                activeTabKey={activeTabKey}
                onTabChange={(key) => SetActiveKey(key)}>
                <Table
                    loading={loadingData}
                    size="small"
                    bordered={true}
                    pagination={{ pageSizeOptions: [10, 20, 30, 40], position: ['bottomRight'], showSizeChanger: true }}
                    columns={activeTabKey === SSPTabs.GROUPS ? groupColumns : userColumns}
                    dataSource={activeTabKey === SSPTabs.GROUPS ? groupDataSource : userDataSource} />
            </Card>
        </div>);
}

export default UsersPage;