import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { Group } from '../common/models/Group';
import { Holiday } from '../common/models/Holiday';
import { User } from '../common/models/User';
import { RootState } from './store';

export interface ConnectionState {
  config: {[key: string]: string}
  prompts:{[key: string]: {[key: string] : string}}
  flags: {[key: string]: string}
  hours: {[key: string]: string}
  holidays: {[key: string]: string}
  users: {[key: string]: User}
  groups: {[key: string]: Group}
}

const initialState: ConnectionState = {
  config: {},
  prompts: {},
  flags: {},
  hours: {},
  holidays: {},
  users: {},
  groups: {}
};

export const cacheSlice = createSlice({
  name: 'cache',
  initialState,
  reducers: {
    CacheUsers: (state, action) => {state.users[action.payload.name] = action.payload.body},
    CacheGroups: (state, action) => {state.groups[action.payload.name] = action.payload.body},
    CacheConfig: (state, action) => {state.config[action.payload.name] = action.payload.body},
    CachePrompts: (state, action) => {state.prompts[action.payload.name] = action.payload.body},
    CacheFlags: (state, action) => {state.flags[action.payload.name] = action.payload.body},
    CacheHours: (state, action) => {state.hours[action.payload.name] = action.payload.body},
    CacheHolidays: (state, action) => {
      let holidays: { [key: string] : Holiday } = action.payload.body;
      let sortedHolidays: any = {};
      Object
        .keys(holidays)
        .sort((a, b) => moment(a, 'MMDDYYYY').diff(moment(b, 'MMDDYYYY')))
        .map((index) => sortedHolidays[' '+index] = holidays[index]);
        /*To avoid caching complex object(Map in es6), and to prevent
        the automatic key number sort in js the extra space in the key is required. - dan*/
      state.holidays[action.payload.name] = sortedHolidays;
    },
    InvalidateUsersCache: (state) => {state.users = {}},
    InvalidateGroupsCache: (state) => {state.groups = {}},
    InvalidateConfigCache: (state) => {state.config = {}},
    InvalidatePromptsCache: (state) => {state.prompts = {}},
    InvalidateFlagsCache: (state) => {state.flags = {}},
    InvalidateHoursCache: (state) => {state.hours = {}},
    InvalidateHolidaysCache: (state) => {state.holidays = {}},
  },
});

export const GetItem = (state: RootState, args: any) => console.log(args);
export const GetCachedUsers = (state: RootState) => state.cache.users;
export const GetCachedGroups = (state: RootState) => state.cache.groups;
export const GetCachedConfigs = (state: RootState) => state.cache.config;
export const GetCachedPrompts = (state: RootState) => state.cache.prompts;
export const GetCachedFlags = (state: RootState) => state.cache.flags;
export const GetCachedHours = (state: RootState) => state.cache.hours;
export const GetCachedHolidays = (state: RootState) => state.cache.holidays;

export const {
  InvalidateUsersCache,
  InvalidateGroupsCache,
  InvalidateConfigCache,
  InvalidateFlagsCache,
  InvalidateHolidaysCache,
  InvalidateHoursCache,
  InvalidatePromptsCache,
  CacheUsers,
  CacheGroups,
  CacheHours,
  CacheHolidays,
  CacheConfig,
  CachePrompts,
  CacheFlags,
} = cacheSlice.actions;

export default cacheSlice.reducer;
