export enum StandardAttributes {
    ANI='ani',
    DNIS='dnis',
    MAIN='main',
    CALL_RECORDING='callRecording',
    GLOBAL_MOTD='globalMOTD',
    HOLIDAY='holiday',
    HOLIDAY_AH='holidayAH',
    CLOSED='closed',
    CLOSED_AH='closedAH',
    EMERGENCY='emergency',
    EMERGENCY_AH='emergencyAH',
    INCLEMENT_WEATHER='inclementWeather',
    CUST_LOOKUP_ANI='custLookupANI',
    CUST_NUMBER_INTENT='custNumberIntent',
    TICKET_NUMBER_INTENT='tckNumberIntent',
    TICKET_LOOK_UP='tcktLookup',
    PURPOSE_INTENT='purposeIntent',
    PURPOSE_LOOKUP='purposeLookup',
    TARGET_QUEUE='targetQueue',
    HOLIDAY_Q='holiday_Q',
    HOLIDAY_AH_Q='holidayAH_Q',
    HOLIDAY_VM_Q='holidayVM_Q',
    EMERGENCY_Q='emergency_Q',
    EMERGENCY_AH_Q='emergencyAH_Q',
    EMERGENCY_VM_Q='emergencyVM_Q',
    CLOSED_Q='closed_Q',
    CLOSURE_TYPE='closureType',
    CLOSED_AH_Q='closedAH_Q',
    CLOSED_VM_Q='closedVM_Q',
    QUEUE_MOTD='queueMOTD',
    NO_AGENT='noAgent',
    NO_AGENT_Q='noAgent_Q',
    NO_AGENT_AH='noAgentAH_Q',
    CALL_RECORDING_OPT_OUT_ENABLED='callRecordingOptOutEnabled',
    CALL_RECORDING_OPT_OUT='callRecordingOptOut',
    TICKET_NUMBER_INTENT_Q='tcktNumberIntent_Q',
    TICK_LOOKUP_Q='tcktLookup_Q',
    VM_Q='vm_Q',
    BILLING_ID='billingId',
    ERROR='error',
    ERROR_REASON='errorReason',
    LAST_JOURNEY_POINT='lastJourneyPoint',
    JOURNEY_MAP='journeyMap',
    BU_GROUP='buGroup',
    BU='bu',
    SUB_BU='subBU',
    CLIENT='client',
    TEAM='team',
    CALL_DISPOSITION='callDisposition',
    LOCATION_DEPARTMENT='locationDepartment',
    PATIENT_STATUS='patientStatus',
    NON_SCHEDULING_CALLS='nonSchedulingCalls',
    CALLBACK_ENABLED='callbackEnabled',
    CALLBACK_OFFSET_HOURS='callbackOffsetHours',
    CALLBACK_REQUESTED='callbackRequested',
    AFTER_HOURS_NOTIFICATIONS_ENABLED='afterHoursNotificationsEnabled',
    AH_VOICEMAIL_OFFERED='ahVoicemailOffered',
    AH_NOTIFICATIONS_ENABLED='ahNotificationsEnabled',
    AH_NOTIFICATIONS_REQUESTED_BY_CALLER='ahNotificationsRequestedByCaller',
    AH_ON_CALL_PHONE_NUMBER='ahOncallPhoneNumber',
    AH_ON_CALL_RETRY_MAX='ahOncallRetryMax',
    AH_ESCALATION_ENABLED='ahEscalationEnabled',
    AH_ESCALATION_PHONE_NUMBER='ahEscalationPhoneNumber',
    AH_ESCALATION_ATTEMPT_MAX='ahEscalationAttemptMax',
    AH_NOTIFICATION_ATTEMPTS='ahNotificatonAttempts',
    AH_VOICEMAIL_LISTENED_TO_ON_CALL='ahVoicemailListenedToOnCall',
    AH_ESCALATION_ATTEMPTS='ahEscalationAttempts',
    AH_VOICEMAIL_LISTENED_TO_ESCALATION='ahVoicemailListenedToEscalation',
    AH_VOICEMAIL_REPEATED='ahVoicemailRepeated',
    AH_VOICEMAIL_REPEAT_COUNT='ahVoicemailRepeatCount',
    CALLBACK_QUEUE='callbackQueue',
    TARGET_QUEUE_ARN='targetQueueARN',
    CALLBACK_QUEUE_ARN='callbackQueueARN',
    CALLBACK_NUMBER='callbackNumber',
    LANGUAGE='language',
    MOH_LOOP_COUNT='mohLoopCount',
    TIME_ZONE='timeZone',
    OFFSHORE_QUEUE='offshoreQueue',
    OFFSHORE_QUEUE_ARN='offshoreQueueARN',
    LEX='lex',
    BOT_PERCENTAGE_ALLOCATION='botPercentageAllocation',
    BOT_TRANSFER_DID='botTransferDID',
    BOT_RETURNED_CALL_PRIORITY='botReturnedCallPriority',
    CONNECT_API_ENABLED='isConnectApiEnabled',
    OUTBOUND_CALLER_ID='outboundCallerId',
    ENABLE_AH_SUPPORT='EnableAfterHoursSupport',
    AH_TRANSFER_DID='AhTransferDID',
    AH_SUPPORT_QUEUE='AhSupportQueue',
    AH_CONTACT_FLOW_ARN='AhContactFlowARN',
    DR_ENABLE='disasterRecoveryEnabled',
    DR_TRANSFER_DID='drTransferDID',
    TARGET_SPANISH_QUEUE_TUPA = 'targetSpanishQueue'

}