import logo from '../../optum-logo-ora-rgb.svg';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRESTClient } from "../../common/interfaces/IRestClient";
import { StatusCode } from "../../common/enums/StatusCode";
import { AuthResponse } from "../../common/models/Response";
import { GetUserRoles, GetUserStatus, SetUserIdToken, SetUserName, SetUserRefreshToken, SetUserRoles, SetUserStatus, SetUserTargetGroups } from "../../app/userSlice";
import { UserStatus } from "../../common/enums/UserStatus";
import { SetApplicationPage, SetApplicationStatus, SetFocusedTargetGroup } from "../../app/appSlice";
import { ApplicationStatus } from '../../common/enums/ApplicationStatus';
import ErrorPage from './pages/ErrorPage';
import { GetThemeBackground, GetThemeTextColor } from '../../app/themeSlice';
import CHCHeader from './CHCHeader';
import CHCContent from './CHCContent';
import { ApplicationPage } from '../../common/enums/ApplicationPage';
import LoadingPage from './pages/LoadingPage';

export interface LayoutProps {
  restClient: IRESTClient
}

export interface CollapseProps {
    _key: boolean,
    label: string,
    callback: any
}

//** The layout is the highest level custom component that sits just below the App component itself. This is responsible for maintaining the SoftPhone Visual and Web Socket Connection at the top level as well as a general structure for the page. */
const CHCLayout = (props: LayoutProps) => {
  const { restClient } = props;
  const roles = useSelector(GetUserRoles);
  const userStatus = useSelector(GetUserStatus);
  const dispatch = useDispatch();
  const background = useSelector(GetThemeBackground);
  const color = useSelector(GetThemeTextColor);
  const layoutStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    background: background,
    color: color,
    minHeight: '100vh'
  }

  const RedirectToLogin = () => {
    try {
      let redirect = `${process.env.REACT_APP_AUTH_BASE!}/login?client_id=${process.env.REACT_APP_REST_CLIENT_ID!}&response_type=code&scope=openid&redirect_uri=${process.env.REACT_APP_REDIRECT!}`
      window.location.href=redirect;
    } catch (error) {
      console.log('Something went wrong when constructing the redirect url, using the default');
      window.location.href=`https://www.changehealthcare.com`;
    }
  }

  //** Called each time the component is loaded... */
  useEffect(() => {
    //** This method parses the target groups from the authentication response. */
    const ParseRolesAndQueuesFromResponse = (response: AuthResponse) => {
      let targetGroups = response.GetTargetGroups();
      let roles = response.GetRoles();
      if(roles.length === 0 || targetGroups.length === 0) {
        dispatch(SetApplicationStatus(ApplicationStatus.IDLE));
        dispatch(SetUserStatus(UserStatus.OFFLINE));
      } else {
        dispatch(SetUserTargetGroups(targetGroups));
        dispatch(SetFocusedTargetGroup(response.GetTargetGroups()[0]));
        dispatch(SetUserRoles(roles));
        dispatch(SetUserStatus(UserStatus.ONLINE));
      }
    }

    //** This method passes the given auth code to the rest clients Authenticate method to fetch open id tokens from the api. */
    const GetUserData = async (authCode: string) => {
      const response: AuthResponse = await restClient.Authenticate(authCode);
      if(response.statusCode===StatusCode.BAD_REQUEST) {
        dispatch(SetUserStatus(UserStatus.UNAUTHORIZED));
        setTimeout(() => RedirectToLogin(), 3000)
        return;
      }
      if(response.statusCode===StatusCode.SUCCESS) {
        dispatch(SetUserName(response.GetUserName()));
        dispatch(SetUserIdToken(response.GetIDToken()));
        dispatch(SetUserRefreshToken(response.GetRefreshToken()));
        ParseRolesAndQueuesFromResponse(response);
        dispatch(SetApplicationStatus(ApplicationStatus.RUNNING));
        dispatch(SetApplicationPage(ApplicationPage.QUEUES));
        return;
      }
    }

    if (userStatus === UserStatus.AUTHENTICATING) {
      let currentUrl = window.location.href;
      if (process.env.NODE_ENV==='development')
        currentUrl = process.env.REACT_APP_REDIRECT!.concat('?code=123-345-123-321-332211');

      if (currentUrl.includes(process.env.REACT_APP_REDIRECT!.concat('?code='))) {
        let splitUrl = currentUrl.split('?code=');
        const authCode = splitUrl[splitUrl.length - 1];
        if(authCode)
          GetUserData(authCode);
      } else {
        dispatch(SetUserStatus(UserStatus.UNAUTHORIZED));
        setTimeout(() => RedirectToLogin(), 3000)
      }
    }

  }, [ restClient, roles, dispatch, userStatus, background ])

  return(
    <div style={layoutStyle}>
        {userStatus===UserStatus.AUTHENTICATING && <LoadingPage/>}
        {userStatus===UserStatus.UNAUTHORIZED && <ErrorPage errorCode={400} errorMessage="Your session has expired or you do not have access to view this page. You will be redirected to the login page in (3) seconds."/>}
        {userStatus===UserStatus.OFFLINE && <ErrorPage errorCode={400} errorMessage="You are not authorized to view this page..."/>}
        {userStatus===UserStatus.ONLINE && 
          <>
            <CHCHeader src={logo}/>
            <CHCContent client={restClient}/>
          </>}
    </div>);
}

export default CHCLayout