import { createSlice } from '@reduxjs/toolkit';
import { ApplicationPage } from '../common/enums/ApplicationPage';
import { ApplicationStatus } from '../common/enums/ApplicationStatus';
import { Language } from '../common/enums/Language';
import { RootState } from './store';

export interface AppState {
  language: Language | string,
  page: ApplicationPage;
  previousStatus: ApplicationStatus;
  status: ApplicationStatus;
  siderCollapsed: boolean;
  focusedTargetGroup?: string;
  focusedTargetGroups: Record<string,string>;
  drawerOpen: boolean;
}

const initialState: AppState = {
  language: "ENGLISH",
  page: ApplicationPage.LOADING,
  previousStatus: ApplicationStatus.LOADING,
  status: ApplicationStatus.LOADING,
  siderCollapsed: false,
  drawerOpen: false,
  focusedTargetGroups: {}
};

export const appSlice = createSlice({
  name: 'ssp',
  initialState,
  reducers: {
    SetApplicationLanguage: (state, action) => {state.language = action.payload; },
    SetApplicationPage: (state, action) => { state.page = action.payload; },
    SetApplicationStatus: (state, action) => { state.previousStatus = state.status; state.status = action.payload; },
    SetSiderCollapsed: (state, action) => { state.siderCollapsed = action.payload; },
    SetDrawerOpen: (state, action) => { state.drawerOpen = action.payload; },
    SetFocusedTargetGroup: (state, action) => { state.focusedTargetGroup = action.payload; },
    SetFocusedTargetGroups: (state, action) => { state.focusedTargetGroups = action.payload; },
    RemoveFromFocusedTargetGroups: (state, action) => { delete(state.focusedTargetGroups[action.payload]); },
  },
});

export const GetAppLanguage = (state: RootState) => state.application.language;
export const IsDrawerOpen = (state: RootState) => state.application.drawerOpen;
export const GetAppPage = (state: RootState) => state.application.page;
export const GetAppStatus = (state: RootState) => state.application.status;
export const GetAppFocusedTargetGroup = (state: RootState) => state.application.focusedTargetGroup;
export const GetAppFocusedTargetGroups = (state: RootState) => state.application.focusedTargetGroups;
export const IsSiderCollapsed = (state: RootState) => state.application.siderCollapsed;

export const {
  SetFocusedTargetGroups,
  SetApplicationLanguage,
  SetDrawerOpen,
  SetApplicationPage,
  SetFocusedTargetGroup,
  SetApplicationStatus,
  SetSiderCollapsed,
  RemoveFromFocusedTargetGroups
} = appSlice.actions;

export default appSlice.reducer;
