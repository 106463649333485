import jwtDecode from "jwt-decode";
import { Role } from "../../common/enums/Role";
import { StatusCode } from "../../common/enums/StatusCode";
import { IRESTClient } from "../../common/interfaces/IRestClient";
import { Group } from "../../common/models/Group";
import { APIResponse, AuthResponse, Response } from "../../common/models/Response";

//** Runtime Implmentation of the REST Client that will eventually make calls to the API to populate the data and allow for changes to be made withi the UI */
export class RESTClientImpl implements IRESTClient {
    static INSTANCE: IRESTClient;
    static adminUtilsUri: string;
    static userMgmtUri: string;
    static authUri: string;

    private constructor(adminUtilsUri: string, authUri: string, userMgmtUri: string) {
        RESTClientImpl.adminUtilsUri = adminUtilsUri;
        RESTClientImpl.authUri = authUri;
        RESTClientImpl.userMgmtUri = userMgmtUri;
    }

    async GetChangeLogs(value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/logs');
        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }
        /* TODO: Add response handling to the other API Calls in here - dan */
        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        return apiResponse;
    }

    async RemoveGroup(group: Group, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_USER_API_BASE!.concat('/groups');

        let request = {
            method: 'DELETE',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: JSON.stringify(group)
        }

        const response = await fetch(uri, request)
        if(!response.ok)
            apiResponse.setStatus(response.status);
        return apiResponse;
    }

    async UpdateGroup(updatedGroup: Group, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_USER_API_BASE!.concat('/groups');

        let request = {
            method: 'PATCH',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: JSON.stringify(updatedGroup)
        }

        const response = await fetch(uri, request)
        if(!response.ok)
            apiResponse.setStatus(response.status);
        return apiResponse;
    }

    async GetGroups(token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_USER_API_BASE!.concat('/groups');

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        
        apiResponse.body = response;
        return apiResponse;
    }
    
    async GetUsers(token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_USER_API_BASE!.concat('/users');

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        
        apiResponse.body = response;
        return apiResponse;
    }
    
    async AddGroup(group: Group, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_USER_API_BASE!.concat('/groups');
        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: JSON.stringify(group)
        }

        const response = await fetch(uri, request)
        if(!response.ok)
            apiResponse.setStatus(response.status);
        return apiResponse;
    }
    
    async AddUserToGroup(user: string, group: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_USER_API_BASE!.concat(`/groups/${group}/${user}`);

        let request = {
            method: 'PUT',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        if(!response.ok)
            apiResponse.setStatus(response.status);
        return apiResponse;
    }

    async RemoveUserFromGroup(user: string, group: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_USER_API_BASE!.concat(`/groups/${group}/${user}`);

        let request = {
            method: 'DELETE',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        if(!response.ok)
            apiResponse.setStatus(response.status);
        return apiResponse;
    }

    async GetHours(targetGroup: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/').concat(targetGroup).concat('/hours');

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        
        apiResponse.body = response;
        return apiResponse;
    }

    async GetHolidays(targetGroup: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/').concat(targetGroup).concat('/holidays');

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        
        apiResponse.body = response;
        return apiResponse;
    }

    async GetDispositions(targetGroup: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/').concat(targetGroup).concat('/dispositions');

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        
        apiResponse.body = response;
        return apiResponse;
    }

    async SetHours(targetGroup: string, value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/').concat(targetGroup).concat('/hours');

        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: value
        }
        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }

    async SetHolidays(targetGroup: string, value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/').concat(targetGroup).concat('/holidays');

        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: value
        }
        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }

    async SetDispositions(targetGroup: string, key: string, value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/').concat(targetGroup).concat('/dispositions/').concat(key);

        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: value
        }
        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }

    static Create(adminUtilsUri: string, authUri: string, userMgmtUri: string) { 
        if (RESTClientImpl.INSTANCE) {
            return RESTClientImpl.INSTANCE;
        }
        RESTClientImpl.INSTANCE = new RESTClientImpl(adminUtilsUri, authUri, userMgmtUri);
        return RESTClientImpl.INSTANCE;
    }

    async Authenticate(authCode: string): Promise<AuthResponse> {
        // Make API Call and try to parse the response
        let uri = process.env.REACT_APP_AUTH_BASE!.concat('/oauth2/token');
        let request = {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: new URLSearchParams({
                grant_type: "authorization_code",
                code: authCode,
                redirect_uri: process.env.REACT_APP_REDIRECT!,
                client_id: process.env.REACT_APP_REST_CLIENT_ID!
            })
        }

        const response = await fetch(uri, request);
        if(!response.ok)
            return new AuthResponse(StatusCode.BAD_REQUEST, '', [], '', '', '', [])

        const result = await response.json();
        // TODO: Check the status before we parse - dan
        try {
            var parsedToken: any = jwtDecode(result.id_token);
            let username: string = parsedToken['cognito:username']
            let roleArns: string[] = parsedToken['cognito:roles']
            let groups: string[]= parsedToken['cognito:groups']
            let targetGroups: string[] = [];
            let roles: Role[] = [];

            try {
                roleArns.forEach((role_arn: string) => {
                    if (role_arn === process.env.REACT_APP_AGENT_ARN!) {
                        roles.push(Role.AGENT);
                    }
                    if (role_arn === process.env.REACT_APP_DEVELOPER_ARN!) {
                        roles.push(Role.DEVELOPER);
                    }
                    if (role_arn === process.env.REACT_APP_SUPERVISOR_ARN!) {
                        roles.push(Role.SUPERVISOR);
                    }
                    if (role_arn === process.env.REACT_APP_ADMINISTRATOR_ARN!) {
                        roles.push(Role.ADMINISTRATOR);
                    }
                });
    
                groups.forEach((group: string) => {
                    if (group.match(new RegExp(process.env.REACT_APP_GROUP_FILTER_PATTERN!))!==null) {
                        targetGroups.push(group);
                    }
                });
            } catch (error) { } // Eating this exception for now

            return new AuthResponse( StatusCode.SUCCESS, username, targetGroups, result.access_token, result.id_token, result.refresh_token, roles)

        } catch (error) {
            return new AuthResponse(StatusCode.FORBIDDEN, '', [], '', '', '', [])
        }
    }

    async GetDefaultConfig(targetGroup: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/').concat(targetGroup).concat('/config');

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        
        apiResponse.body = response;
        return apiResponse;
    }

    async GetPrompts(targetGroup: string, locale: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/').concat(targetGroup).concat('/prompts/').concat(locale);

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
            .then(response => response.json())
            .then(data => {return data});
        apiResponse.body = response;
        return apiResponse;
    }

    async GetDynamicFlags(targetGroup: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/').concat(targetGroup).concat('/flags');

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }

    async SetPrompt(targetGroup: string, locale: string, value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/').concat(targetGroup).concat('/prompts/').concat(locale);

        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: value
        }
        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }
    
    async UploadPromptWav(targetGroup: string, locale: string, value: any, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/').concat(targetGroup).concat('/prompts/').concat(locale);

        let request = {
            method: 'POST',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: JSON.stringify(value)
        }
        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        try {
            apiResponse.errorMessage = apiResponse.body['errorMessage'];
        } catch (error) {
            try {
                apiResponse.errorMessage = apiResponse.body['message'];
            } catch (error) {
                // no valid error message could be parsed from the response body.
            }
        }
        return apiResponse;
    }

    async SetDynamicFlag(targetGroup: string, value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/').concat(targetGroup).concat('/flags');

        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: value
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }
    
    async SetDefaultConfig(targetGroup: string, value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = process.env.REACT_APP_REST_API_BASE!.concat('/').concat(targetGroup).concat('/config');

        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: value
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }

    async Delete(key: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        return response;
    }
}