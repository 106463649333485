/**
 * Group Data
 */
 export interface IGroup {
    id?: string | number;
    groupName: string;
    description?: string;
}

/**
 * Model definition of a group within the SSP.
 */
export class Group implements IGroup {
    id?: string | number;
    groupName!: string;
    description?: string;
    constructor(groupName: string, description?: string, id?: string| number) {
        this.groupName=groupName;
        if(description!==undefined) {
            this.description=description;
        }
        if(id!==undefined) {
            this.id=id;
        }
    }
}