import jwtDecode from "jwt-decode";
import { EventType } from "../../common/enums/EventType";
import { Role } from "../../common/enums/Role";
import { StatusCode } from "../../common/enums/StatusCode";
import { IRESTClient } from "../../common/interfaces/IRestClient";
import { Group } from "../../common/models/Group";
import { Holiday } from "../../common/models/Holiday";
import { APIResponse, AuthResponse, Response } from "../../common/models/Response";


//** Unit Testing Implementation contains Mock Stubbed Responses that can be used to Happy Path Test the UI as well as construct the API Specification. */
export class RESTClientUTImpl implements IRESTClient {

    static INSTANCE: IRESTClient;
    MOCK_CONFIG: any;
    MOCK_GROUPS: any;
    MOCK_USERS: any;
    /**Private Constructor to ensure this object can only be created via the Create method. This ensures that we are forcing validating on the object instantiation
     * such that we create a new instance only if one does not exist.
     */
    private constructor(adminUtilsUri: string, authUri: string, userRestApiBaseUri: string) {
        this.MOCK_CONFIG = {
            "meta_data": {
                "version": "1.0.0",
                "description": "TES Patient Access Center Beebe Health Care Contact Center Configuration"
            },
            "prompts": {
                "en-US": {
                    "callbackAtAniPrompt": "<speak>We can call you back at <say-as interpret-as=\"telephone\">$.Attributes.ani</say-as>.<break strength=\"strong\"/>To set this as your callback number press 1, <break strength=\"strong\"/>To set a different call back number,<break/>,press 2.</speak>",
                    "callbackConfirmationPrompt": "<speak>Your call back request has been set. You will be called back when the next agent is available. This call will disconnect, Goodbye!</speak>",
                    "callbackErrorPrompt": "<speak>We are unable to process your callback request at this time. Please wait for the next available agent.</speak>",
                    "callbackNumberConfirmationPrompt": "<speak>you entered  <say-as interpret-as=\"telephone\">$.Attributes.callbackNumber</say-as>.<break strength=\"strong\"/> Press 1 if you want us to call you back at this number, or press 2 to enter a different number.</speak>",
                    "callbackNumberEntryPrompt": "<speak>Enter the number you would like to be called back at.</speak>",
                    "callbackNumberInvalidPrompt": "<speak>The phone number you entered is invalid. Let's try that again.</speak>",
                    "emergencyClosure": "Due to unforeseen circumstances, our scheduling department is temporarily closed. We are quickly working to resolve this issue and we apologize for the inconvenience. If this is a true medical emergency please hang up and dial 911.",
                    "holdPrompt": "Thank you for holding. Rather than wait on hold, we can call you back. If you would like to receive a call back, please press one now",
                    "holdPrompt1": "Please hold for the next available agent.",
                    "holidayPrompt": "Thank you for calling. You have reached our scheduling department, after normal business hours. If this is a life threatening emergency, please hang-up and dial 911. You can contact our office staff during normal business hours from Monday to Friday 8 am to 6 pm.",
                    "incWeather": "Thank you for holding, due to the inclement weather we are experiencing an unusually high volume of calls. Please continue to hold and we'll be with you as soon as possible.",
                    "moh1": "s3://chcvoice-chc-dev-connect-tes-rcm-hs-2661-prompts/TES_PAC_BEEBE/Beebe_MOH1.wav",
                    "moh2": "s3://chcvoice-chc-dev-connect-tes-rcm-hs-2661-prompts/TES_PAC_BEEBE/Beebe_MOH2.wav",
                    "moh3": "s3://chcvoice-chc-dev-connect-tes-rcm-hs-2661-prompts/TES_PAC_BEEBE/Beebe_MOH3.wav",
                    "noAgentPrompt": "Due to unforeseen circumstances, we are unable to answer your call at this time. Please try your call again later.",
                    "qualityPurpose": "For quality assurance purposes, your call may be monitored or recorded.",
                    "queueMOTD": "Sample message of the day."
                }
            },
            "numbers": {
                "dev": {
                    "12062192999": "TES_PAC_MS_IMA_NEW_ENG"
                },
                "qa": {
                    "18335674666": "TES_PAC_MS_IMA_NEW_ENG"
                }
            },
            "queues": {
                "TES_PAC_MS_IMA_NEW_ENG": {
                    "bu": "TES",
                    "callbackQueue": "TES_PAC_MS_IMA_NEW_ENG_Callback",
                    "callbackOffsetHours": 1,
                    "client": "FOO Client",
                    "main": "Foo_main",
                    "mohLoopCount": "2",
                    "subBU": "PAC",
                    "targetQueue": "TES_PAC_MS_IMA_NEW_ENG",
                    "targetQueueARN": "PAC",
                    "team": "CHC Development",
                    "timeZone": "US/Eastern",
                    "language" : "English",
                    "botPercentageAllocation": 0,
                    "botTransferDID": 1235551234,
                    "botReturnedCallPriority": 1,
                    "holidays": {
                        "01012023": {
                            "value": "New Year's Day",
                            "closed": false,
                            "hours" :["12:00am", "05:00 pm"]
                        },
                        "01172023": {
                            "value": "Martin Luther King Jr Day",
                            "closed": true,
                            "hours" :["12:00am", "05:00 pm"]
                        },
                        "05302022": {
                            "value": "Memorial Day",
                            "closed": true,
                            "hours" :["12:00am", "05:00 pm"]
                        },
                        "07042022": {
                            "value": "Independence Day",
                            "closed": true,
                            "hours" :["12:00am", "05:00 pm"]
                        },
                        "09052022": {
                            "value": "Labor Day",
                            "closed": true,
                            "hours" :["12:00am", "05:00 pm"]
                        },
                        "11242022": {
                            "value": "Thanksgiving Day",
                            "closed": false,
                            "hours" :["12:00am", "05:00 pm"]
                        },
                        "12262022": {
                            "value": "Christmas Day",
                            "closed": true,
                            "hours" :["12:00am", "05:00 pm"]
                        }
                    },
                    "hours": {
                        "sunday": ["00:00 am", "00:00 pm"],
                        "monday": ["07:00 am", "06:00 pm"],
                        "tuesday": ["07:00 am", "06:00 pm"],
                        "wednesday": ["07:00 am", "06:00 pm"],
                        "thursday": ["07:00 am", "06:00 pm"],
                        "friday": ["07:00 am", "06:00 pm"],
                        "saturday": ["07:00 am", "05:00 pm"]
                    },
                    "dynamicFlags": {
                        "isConnectApiEnabled": {
                            "description": "Enable or Disable the call to Connect APIs from the lambda.",
                            "value": false
                        },
                        "queueMOTD": {
                            "relativePrompt": "queueMOTD", 
                            "description": "Enable or Disable the Message of the Day prompt for this queue.",
                            "value": false
                        },
                        "emergency": {
                            "relativePrompt": "emergencyClosure", 
                            "description": "Setting this value to true enables the Emergency Prompt.",
                            "value": false
                        },
                        "inclementWeather": {
                            "relativePrompt": "incWeather", 
                            "description": "Setting this value to true enables the Inclement Weather Prompt.",
                            "value": false
                        },
                        "callRecording": {
                            "description": "Enable or Disable call recording for this group's future calls.",
                            "value": false
                        },
                        "callbackEnabled": {
                            "description": "Enable or Disable the callback feature for this group.",
                            "value": false
                        },
                        "callRecordingOptOut": {
                            "description": "Enable or Disable the option for a user to opt out of call recording.",
                            "value": false
                        }
                    }
                },
                "TES_PAC_MS_IMA_EX_ENG": {
                    "bu": "TES",
                    "callbackQueue": "TES_PAC_MS_IMA_EX_ENG_Callback",
                    "callDisposition": "",
                    "client": "FOO Client",
                    "main": "Foo_main",
                    "mohLoopCount": "2",
                    "subBU": "PAC",
                    "targetQueue": "TES_PAC_MS_IMA_EX_ENG",
                    "targetQueueARN": "PAC",
                    "team": "CHC Development",
                    "timeZone": "US/Eastern",
                    "language" : "English",
                    "botPercentageAllocation": 0,
                    "botTransferDID": 1235551234,
                    "botReturnedCallPriority": 1,
                    "holidays": {
                        "01012023": {
                            "value": "New Year's Day",
                            "closed": true,
                            "hours" :["12:00am", "05:00 pm"]
                        },
                        "01172023": {
                            "value": "Martin Luther King Jr Day",
                            "closed": true,
                            "hours" :["12:00am", "05:00 pm"]
                        },
                        "05302022": {
                            "value": "Memorial Day",
                            "closed": true,
                            "hours" :["12:00am", "05:00 pm"]
                        },
                        "07042022": {
                            "value": "Independence Day",
                            "closed": true,
                            "hours" :["12:00am", "05:00 pm"]
                        },
                        "09052022": {
                            "value": "Labor Day",
                            "closed": true,
                            "hours" :["12:00am", "05:00 pm"]
                        },
                        "11242022": {
                            "value": "Thanksgiving Day",
                            "closed": true,
                            "hours" :["12:00am", "05:00 pm"]
                        },
                        "12262022": {
                            "value": "Christmas Day",
                            "closed": true,
                            "hours" :["12:00am", "05:00 pm"]
                        }
                    },
                    "hours": {
                        "sunday": ["00:00 am", "00:00 pm"],
                        "monday": ["07:00 am", "06:00 pm"],
                        "tuesday": ["07:00 am", "06:00 pm"],
                        "wednesday": ["07:00 am", "06:00 pm"],
                        "thursday": ["07:00 am", "06:00 pm"],
                        "friday": ["07:00 am", "06:00 pm"],
                        "saturday": ["07:00 am", "05:00 pm"]
                    },
                    "dynamicFlags": {
                        "isConnectApiEnabled": {
                            "description": "Enable or Disable the call to Connect APIs from the lambda.",
                            "value": false
                        },
                        "queueMOTD": {
                            "relativePrompt": "queueMOTD", 
                            "description": "Enable or Disable the Message of the Day prompt for this queue.",
                            "value": false
                        },
                        "emergency": {
                            "relativePrompt": "emergencyClosure", 
                            "description": "Setting this value to true enables the Inclement Weather Prompt.",
                            "value": false
                        },
                        "inclementWeather": {
                            "relativePrompt": "incWeather", 
                            "description": "Setting this value to true enables the Inclement Weather Prompt.",
                            "value": false
                        },
                        "callRecording": {
                            "description": "Enable or Disable call recording for this group's future calls.",
                            "value": false
                        },
                        "callbackEnabled": {
                            "description": "Enable or Disable the callback feature for this group.",
                            "value": false
                        },
                        "callRecordingOptOut": {
                            "description": "Enable or Disable the option for a user to opt out of call recording.",
                            "value": false
                        }
                    }
                }
            },
            "dispositions": {
                "callDispositon": {
                    "ChooseCallDisposition": "(Choose One)",
                    "ScheduledAppointment": "Scheduled Appointment LAB/XRAY/EKG",
                    "ScheduledMammogram": "Scheduled Mammogram/Bone Density",
                    "ScheduledAppts": "Scheduled Multiple Appointments",
                    "RescheduleAppt": "Rescheduled Appointment",
                    "XAppt": "Canceled Appointment",
                    "ConfirmedAppt": "Confirmed Appointment Only",
                    "UpsetPT": "Upset Patient Escalation",
                    "NoSchedule": "Scheduling Call- No Schedule or Message",
                    "TransferredNonEmer": "Scheduling Call-Tranferred per Protocol (non-emergent)",
                    "NonScheduling": "Non Scheduling",
                    "DemoUpdate": "Demographic Update",
                    "AbsentCaller": "Absent or Disconnected Caller"
                },
                "locationDepartment": {
                    "ChooseCallDisposition": "(Choose One)",
                    "LocationNA": "N/A",
                    "LocationBMC": "Beebe Healthcare - Main Hospital - BMC",
                    "LocationBHC": "Beebe Health Campus- BHC",
                    "LocationSGT": "Beebe Healthcare - Georgetown - SGT",
                    "LocationLN": "Beebe Healthcare - Long Neck - LN",
                    "LocationMV": "Beebe Healthcare - Millville - MV",
                    "LocationMT": "Beebe Healthcare - Milton - MT",
                    "LocationMBS": "Beebe Healthcare - Millsboro - MBS",
                    "LocationSouthCostal": "Beebe Healthcare - South Coastal Campus"
                },
                "nonSchedulingCalls": {
                    "ChooseCallDisposition": "(Choose One)",
                    "NonSchedulingNA": "N/A",
                    "NonSchedulingGenInfo": "General Office Information",
                    "NonSchedulingMedRecordsReq": "Medical Records and Patient History Requests",
                    "NonSchedulingNoRetCall": "No Return Call from Office/Have not heard from office",
                    "NonSchedulingPTFormInq": "Patient Form Inquiry",
                    "NonSchedulingLatePT": "Patient Late for Appointment",
                    "NonSchedulingPTReturnCall": "Returning Call from Office (Non-Scheduling)",
                    "NonSchedulingResults": "Test Results",
                    "NonSchedulingNoHIPAA": "Unable to Verify HIPAA",
                    "NonSchedulingWorkersComp": "Workers Comp",
                    "NonSchedulingWrongQueue": "Wrong Queue",
                    "NonSchedulingTest": "Test Call"
                },
                "patientStatus": {
                    "ChooseCallDisposition": "(Choose One)",
                    "PTStatusNew": "New Patient",
                    "PTStatusEstablished": "Established Patient",
                    "PTStatusNotRelated": "Not Patient Related"
                }
            }
        };
        this.MOCK_GROUPS = [
            {
                "groupName": "supervisor",
                "description": "Self Service Portal Supervisor Role"
            },
            {
                "groupName": "administrator",
                "description": "Self Service Portal Administrator Role"
            },
            {
                "groupName": "developer",
                "description": "Self Service Portal Developer Role"
            },
            {
                "groupName": "TES_PAC_MS_IMA_NEW_ENG",
                "description": "IMA New English Speaking Queue Group"
            },
            {
                "groupName": "TES_PAC_MS_IMA_EX_ENG",
                "description": "IMA Existing English Speaking Queue Group"
            }
        ]
        this.MOCK_USERS = [
            {
                "userName": "foo_@changehealthcare.com",
                "email": "foo@bar.com",
                "groups": ['supervisor', 'TES_PAC_MS_IMA_NEW_ENG', 'TES_PAC_MS_IMA_EX_ENG']
            },
            {
                "userName": "bar_@changehealthcare.com",
                "groups": ['supervisor','administrator', 'TES_PAC_MS_IMA_NEW_ENG', 'TES_PAC_MS_IMA_EX_ENG']
            },
            {
                "userName": "new_hire@changehealthcare.com",
                "email": "new_hire@chc.com",
                "groups": []
            }
        ]
    }

    /** Testing function to mock the call to fetch change logs. */
    async GetChangeLogs(value: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS);
        response.body = [
            {
                id: '00c04356-9030-11ed-a1eb-0242ac120002',
                event_type: EventType.ConfigUpdate,
                time_stamp: '1673647757',
                source: 'mock_user',
                message: JSON.stringify({'event_type': 'configUpdate', 'time_stamp': '1673648132', 'ttl': '1674252931', 'source': 'PingOne_mock.user@changehealthcare.com', 'message': '{"globalMOTD": "None", "callbackQueue": "TES_PAC_MS_IMA_NEW_SPA", "billingId": "2019432", "bu": "TES", "team": "Mt.Sinai New York", "subBU": "PAC", "client": "MS", "main": "MtSinai_Main", "language": "ENGLISH_AU", "botPercentageAllocation": "95", "botTransferDID": "1235551234", "botReturnedCallPriority": "1"}', 'id': '172f4060-f7cd-4f59-9fc8-6f1b41b84891'})
            },
            {
                id: '059436f8-9030-11ed-a1eb-0242ac120002',
                event_type: EventType.UserUpdate,
                time_stamp: '1673556449',
                source: 'mock_user1',
                message: JSON.stringify({'event_type': 'configUpdate', 'time_stamp': '1673648132', 'ttl': '1674252931', 'source': 'PingOne_mock.user@changehealthcare.com', 'message': '{"globalMOTD": "None", "callbackQueue": "TES_PAC_MS_IMA_NEW_SPA", "billingId": "2019432", "bu": "TES", "team": "Mt.Sinai New York", "subBU": "PAC", "client": "MS", "main": "MtSinai_Main", "language": "ENGLISH_AU", "botPercentageAllocation": "95", "botTransferDID": "1235551234", "botReturnedCallPriority": "1"}', 'id': '172f4060-f7cd-4f59-9fc8-6f1b41b84891'})
            },
            {
                id: '723002y3-1841-31xp-v2gf-3212axs32003',
                event_type: EventType.UserUpdate,
                time_stamp: '1673639772',
                source: 'mock_user1',
                message: JSON.stringify({'event_type': 'configUpdate', 'time_stamp': '1673648132', 'ttl': '1674252931', 'source': 'PingOne_mock.user@changehealthcare.com', 'message': '{"globalMOTD": "None", "callbackQueue": "TES_PAC_MS_IMA_NEW_SPA", "billingId": "2019432", "bu": "TES", "team": "Mt.Sinai New York", "subBU": "PAC", "client": "MS", "main": "MtSinai_Main", "language": "ENGLISH_AU", "botPercentageAllocation": "95", "botTransferDID": "1235551234", "botReturnedCallPriority": "1"}', 'id': '172f4060-f7cd-4f59-9fc8-6f1b41b84891'})
            }
        ];
        return response;
    }

    /** Testing Function to Mock RemoveGroup call response */
    async RemoveGroup(group: Group, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        console.log(`Removing Group: ${JSON.stringify(group)}`);
        return response;
    }

    /** Testing Function to Mock UpdateGroup call response */
    async UpdateGroup(group: Group, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        console.log(`Updating Group: ${JSON.stringify(group)}`);
        return response;
    }

    /** Testing Function to Mock GetGroups call response */
    async GetGroups(token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS);
        let groups = this.MOCK_GROUPS;
        response.body = groups;
        return response;
    }

    /** Testing Function to Mock GetUsers call response */
    async GetUsers(token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS);
        let groups = this.MOCK_USERS;
        response.body = groups;
        return response;
    }
    
    /** Testing Function to Mock AddGroup call response */
    async AddGroup(group: Group, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        console.log(`Adding Group: ${JSON.stringify(group)}`);
        return response;
    }
    
    /** Testing Function to Mock AddUserToGroup call response */
    async AddUserToGroup(user: string, group: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        return response;
    }

    /** Testing Function to Mock RemoveUserFromGroup call response */
    async RemoveUserFromGroup(user:string, group: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        return response;
    }

    /** Singlton implemention for REST Client ( Not thread safe ). This static method in conjuction with the private constructor ensures that there is only ever a single instance of the rest client at a time. */
    static Create(adminUtilsUri: string, authUri: string, userMgmtUri: string) { 
        if (RESTClientUTImpl.INSTANCE) {
            return RESTClientUTImpl.INSTANCE;
        }
        RESTClientUTImpl.INSTANCE = new RESTClientUTImpl(adminUtilsUri, authUri, userMgmtUri);
        return RESTClientUTImpl.INSTANCE;
    }

    /** Testing Function to Mock GetHours call response */
    async GetHours(targetGroup: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        let hours = this.MOCK_CONFIG['queues'][targetGroup]['hours'];
        response.body = hours;
        return response;
    }
    
    /** Testing Function to Mock GetHolidays call response */
    async GetHolidays(targetGroup: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        let holidays = this.MOCK_CONFIG['queues'][targetGroup]['holidays'];
        response.body = holidays;
        return response;
    }
    
    /** Testing Function to Mock GetDispositions call response */
    async GetDispositions(targetGroup: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS);
        response.body = this.MOCK_CONFIG['dispostions'];
        return response;
    }
    
    /** Testing Function to Mock GetDefaultConfig call response */
    async GetDefaultConfig(targetGroup: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS);
        let config = this.MOCK_CONFIG['queues'][targetGroup];
        let _config: any = {}
        Object.keys(config).map((configKey: string) => {
            if(!['hours','holidays','dynamicFlags'].includes(configKey))
                _config[configKey] = config[configKey];
        });
        response.body = _config;
        return response;
    }
    
    /** Testing Function to Mock GetPrompts call response */
    async GetPrompts(targetGroup: string, locale: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        let prompts = this.MOCK_CONFIG['prompts']['en-US'];
        response.body = prompts;
        return response;
    }
    
    /** Testing Function to Mock GetDynamicFlags call response */
    async GetDynamicFlags(targetGroup: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS);
        let flags = this.MOCK_CONFIG['queues'][targetGroup]['dynamicFlags'];
        response.body = flags;
        return response;
    }
    
    /** Submit the authentication code to the token endpoint to retreive access and id tokens */
    async Authenticate(authCode: string): Promise<AuthResponse> {
        var MOCK_ACC_TOKEN = ''
        var MOCK_REF_TOKEN = ''
        var MOCK_ID_TOKEN = ''
        var MOCK_RESPONSE = {
            "access_token": MOCK_ACC_TOKEN,
            "refresh_token": MOCK_REF_TOKEN,
            "id_token": MOCK_ID_TOKEN,
            "token_type": "Bearer",
            "expires_in":3600
        }

        // Make API Call and try to parse the response
        let response = MOCK_RESPONSE
        try {
            // Parse token for roles, target group and user name
            var parsedToken: any = jwtDecode(response.id_token);
            let username: string = parsedToken['cognito:username']
            let roleArns: string[] = parsedToken['cognito:roles']
            let groups: string[]= parsedToken['cognito:groups']
            // Parse the role arn strings in the token and convert them to Enums that the Client can process.
            // TODO: Add this to a helper method. ConvertRoleArnsToRoleEnums(string[]) : Role[]
            let roles: Role[] = [];
            roleArns.forEach((role_arn: string) => {
                // Check for Agent, Developer, and Administrator values in roles.
                if (role_arn === process.env.REACT_APP_AGENT_ARN!) {
                    roles.push(Role.AGENT);
                }
                if (role_arn === process.env.REACT_APP_SUPERVISOR_ARN!) {
                    roles.push(Role.SUPERVISOR);
                }
                if (role_arn === process.env.REACT_APP_ADMINISTRATOR_ARN!) {
                    roles.push(Role.ADMINISTRATOR);
                }
                if (role_arn === process.env.REACT_APP_DEVELOPER_ARN!) {
                    roles.push(Role.DEVELOPER);
                }
            });
            
            // Parse the groups and extract anything that doesnt match the regex filter.
            // TODO: Add this to a helper method.
            let targetGroups: string[] = [];
            groups.forEach((group: string) => {
                // Remove anything that doesnt match the regular expression
                if (group.match(new RegExp(process.env.REACT_APP_GROUP_FILTER_PATTERN!))!==null) {
                    targetGroups.push(group);
                }
            });

            // Build and return the auth response object
            return new AuthResponse(
                StatusCode.SUCCESS,
                username,
                targetGroups,
                response.access_token,
                response.refresh_token,
                response.id_token,
                roles)

        } catch (error) {
            return new AuthResponse(StatusCode.UNAUTHORIZED, '', [], '', '', '', [])
        }
        
    }
    
    /**
     * "Stub the setter responses to mock a successful api response"
     * @param targetGroup
     * @param value 
     * @param token 
     * @returns 
     */
    async SetHours(targetGroup: string, value: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        response.body = JSON.parse(value);
        return response;
    }
    
    /**
     * "Stub the setter responses to mock a successful api response"
     * @param targetGroup
     * @param value 
     * @param token 
     * @returns 
     */
    async SetHolidays(targetGroup: string, value: string, token: string): Promise<Response> {
        console.log(`DEBUG: RequestBody: ${JSON.stringify(value)}`);
        let response = new APIResponse(StatusCode.SUCCESS)
        let data: any = {};
        let defaultHolidays = this.MOCK_CONFIG['queues'][targetGroup]['holidays']
        if(defaultHolidays)
            Object.keys(defaultHolidays).forEach((dateKey: string) => data[dateKey] = defaultHolidays[dateKey]);

        //Iterate the requested data, check if there are holiday definitions in the list that
        // contain the same value (name) and remove them before we append new data.
        Object.entries(JSON.parse(value)).map((entry: any) => {
            let key = entry[0];
            let newDate: Holiday = entry[1];
            // Filter/Reduce the data that contains a duplicate holiday name before we add any of the new data.
            Object.keys(data).map((holidayKey) => {
                let holiday = data[holidayKey]
                if(holiday.value === newDate.value)
                    delete data[holidayKey];
            });
            data[key] = newDate;
        });

        response.body = data;
        console.log(`DEBUG: ResponseBody: ${JSON.stringify(data)}`);
        return response;
    }
    
    /**
     * "Stub the setter responses to mock a successful api response"
     * @param targetGroup
     * @param value 
     * @param token 
     * @returns 
     */
    async SetDispositions(targetGroup: string, key: string, value: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        response.body = JSON.parse(value);
        return response;
    }

    /**
     * "Stub the setter responses to mock a successful api response"
     * @param targetGroup
     * @param value 
     * @param token 
     * @returns 
     */
    async SetPrompt(targetGroup: string, locale: string, value: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        response.body = JSON.parse(value);
        return response;
    }

    /**
     * Stub the Upload Prompt Implmentation for local testing and development.
     * @param targetGroup 
     * @param locale 
     * @param value 
     * @param token 
     * @returns 
     */
    async UploadPromptWav(targetGroup: string, locale: string, value: any, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        response.body = { "errorMessage": "Test"}
        try {
            response.errorMessage = response.body['errorMessage'];
        } catch (error) {
            try {
                response.errorMessage = response.body['message'];
            } catch (error) {
                // no valid error message could be parsed from the response body.
            }
        }
        console.log(value);
        console.log(response)
        return response;
    }

    /**
     * "Stub the setter responses to mock a successful api response"
     * @param targetGroup
     * @param value 
     * @param token 
     * @returns 
     */
    async SetDynamicFlag(targetGroup: string, value: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        response.body = JSON.parse(value);
        return response;
    }
    
    /**
     * "Stub the setter responses to mock a successful api response"
     * @param targetGroup
     * @param value 
     * @param token 
     * @returns 
     */
    async SetDefaultConfig(targetGroup: string, value: string, token: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        response.body = JSON.parse(value);
        return response;
    }

    async Delete(key: string): Promise<Response> { 
        return APIResponse.SUCCESS;
    }

}